import React from "react"
import { Link, graphql } from "gatsby"
import theme from './blog.module.scss'
import themeUtils from 'styles/utils.module.scss'
import MenuToolbar from 'components/MenuToolbar'
import cn from 'classnames'
import DfpAdSlot from 'components/DfpAdSlot'
import { SOFTWARE_DEVELOPER } from 'config/dfpAds'
import { BrowserView } from 'react-device-detect'
import RecentPost from 'components/ui/RecentPost'
import urlManager from 'lib/utils/urlManager'

export default function BlogPage({ data }) {
  const { edges: posts } = data.allMarkdownRemark

  return (
    <>
      <MenuToolbar />
      <div className={theme.Blog}>
        <BrowserView viewClassName={cn(themeUtils.hideXS, theme.Blog_GutterLeft)}>
          <RecentPost />
          <DfpAdSlot className={theme.Blog_Ad_sticky} adKey={SOFTWARE_DEVELOPER} />
        </BrowserView>
        <div className={theme.Blog_Content}>
          <div className={theme.Blog_Container}>
            {posts
              .filter(post => post.node.frontmatter.title.length > 0)
              .map(({ node: post }) => {
                const { frontmatter } = post
                const { type, title, page } = frontmatter
                const pageLink = urlManager.buildSEOpathForContent(type, title, page)

                return (
                  <div className={theme.Blog_Preview} key={post.id}>
                    <h1>
                      <Link to={pageLink}>{post.frontmatter.title}</Link>
                    </h1>
                    <h2>{post.frontmatter.date}</h2>
                    <p>{post.excerpt}</p>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query ArticleQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter:{ type:{eq:"article"}}}
      ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            type
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`